import React, { useEffect } from "react";
import { Typewriter } from "react-simple-typewriter";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import ReviewsContainer from "../Reviews/Review";

import "./Main.scss";
import book from "../../assets/forrester-book.webp";
import logo from "../../assets/logo.png";
import logoWhite from "../../assets/logo-white.png";
import why1 from "../../assets/why-1.svg";
import why2 from "../../assets/why-2.svg";
import why3 from "../../assets/why-3.svg";
import outline from "../../assets/oulines-bg.svg";
import instagram from "../../assets/instagram.png";
import linkedin from "../../assets/linkedin.png";
import youtube from "../../assets/youtube.png";

function Main() {
  const jobOpening = useMotionValue(0);
  const jobOpeningRound = useTransform(jobOpening, Math.round);

  const averageSalary = useMotionValue(0);
  const averageSalaryRound = useTransform(averageSalary, Math.round);

  const globalMarket = useMotionValue(0);
  const globalMarketRound = useTransform(globalMarket, Math.round);

  const workforce = useMotionValue(0);
  const workforceRound = useTransform(workforce, Math.round);

  const quotes = [
    '"In the realm of the digital, vigilance is our strongest ally."',
    '"In the digital age, vigilance is the first line of defense."',
    '"Guardians of the virtual realm, defenders of digital trust."',
  ];

  const moto = [" members", " hackers"];

  useEffect(() => {
    const jobOpeningAnimation = animate(jobOpening, 40, { duration: 2 });
    const averageSalaryAnimation = animate(averageSalary, 60, { duration: 2 });
    const globalMarketAnimation = animate(globalMarket, 222, { duration: 2 });
    const workforceAnmiation = animate(workforce, 4.7, { duration: 2 });

    return () => {
      jobOpeningAnimation.stop();
      averageSalaryAnimation.stop();
      globalMarketAnimation.stop();
      workforceAnmiation.stop();
    };
  }, [jobOpening, averageSalary, globalMarket, workforce]);

  return (
    <>
      <div className="main-holder">
        <h2>
          <span>
            <Typewriter
              words={quotes}
              loop={Infinity}
              typeSpeed={70}
              deleteSpeed={50}
              delaySpeed={1000}
            />
          </span>
        </h2>
      </div>
      <div className="card-holder">
        <div className="info-card-holder">
          <div className="card-num">
            <motion.p>{jobOpeningRound}</motion.p>
            <span>
              <p>K+</p>
            </span>
          </div>
          <div className="card-txt">
            <p>Cyber Security Job Openings in India</p>
          </div>
        </div>
        <div className="info-card-holder">
          <div className="card-num">
            <motion.p>{averageSalaryRound}</motion.p>
            <span>
              <p>K+</p>
            </span>
          </div>
          <div className="card-txt">
            <p>Cyber Security Monthly Salary in India</p>
          </div>
        </div>
        <div className="info-card-holder">
          <div className="card-num">
            <motion.p>{globalMarketRound}</motion.p>
            <span>
              <p>B$</p>
            </span>
          </div>
          <div className="card-txt">
            <p>Global Cyber Security Market Size in 2023</p>
          </div>
        </div>
        <div className="info-card-holder">
          <div className="card-num">
            <motion.p>{workforceRound}</motion.p>
            <span>
              <p>M+</p>
            </span>
          </div>
          <div className="card-txt">
            <p>Global Cyber Security Workforce</p>
          </div>
        </div>
        {/* cards end */}
      </div>
      <p id="aboutus" style={{ width: "100%", height: "1px" }}></p>
      <div className="aboutus-holder">
        <h1>Cracking the Code to Cybersecurity: E-VIGILANTES Revealed</h1>
        <div className="aboutus-con">
          <div className="img-holder">
            <img src={book} alt="book" />
          </div>
          <div className="content-holder">
            <p>
              At <span>Evigilantes</span>, we are a cybersecurity education firm
              focused on penetration testing. We offer a range of services to
              help businesses stay safe in a digital world where cyber threats
              are increasingly prevalent. Our team of experts has years of
              experience in the cybersecurity industry, and we're passionate
              about helping our clients stay one step ahead of the hackers. We
              offer a range of training programs and services to help businesses
              of all sizes stay secure.
            </p>
          </div>
        </div>
      </div>
      <p id="learning" style={{ width: "100%", height: "1px" }}></p>
      <div className="learning-con">
        <div className="learning-title-holder">
          <img src={logo} alt="logo" />
          <h1>What you will learn ?</h1>
        </div>
        <div className="learning-content-holder">
          {/* learning card start */}
          <div className="learning-card">
            <div className="learning-card-img-holder">
              <img src={why2} alt="why-2" />
            </div>
            <div className="learning-card-content-holder">
              <div className="learning-header">
                <h3>Cybersecurity and Ethical Hacking:</h3>
              </div>
              <div className="learning-content">
                <ol>
                  <li>Kali Linux overview</li>
                  <li>Hacking toolset</li>
                  <li>Machine solving - Linux and Windows</li>
                  <li>Bug Bounty (XSS, SQL Injection, IDOR, etc.)</li>
                  <li>Active Directory hacking and infrastructure</li>
                  <li>Android, iOS, Windows, and Mac hacking</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="learning-card">
            <div className="learning-card-img-holder">
              <img src={why3} alt="why-3" />
            </div>
            <div className="learning-card-content-holder">
              <div className="learning-header">
                <h3>Scripting and Programming:</h3>
              </div>
              <div className="learning-content">
                <ol>
                  <li>Bash Scripting</li>
                  <li>Python</li>
                  <li>Building tools</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="learning-card">
            <div className="learning-card-img-holder">
              <img src={why1} alt="why-1" />
            </div>
            <div className="learning-card-content-holder">
              <div className="learning-header">
                <h3>Professional Skills and Career Development:</h3>
              </div>
              <div className="learning-content">
                <ol>
                  <li>Networking</li>
                  <li>Mock interviews</li>
                  <li>Resume building</li>
                  <li>Guaranteed Internship and Job Assistance</li>
                </ol>
              </div>
            </div>
          </div>
          {/* learning card end */}
        </div>
      </div>
      <div className="highlight">
        <div className="highlight-holder">
          <img src={outline} alt="outline" />
          <div className="highlight-con">
            <h1>
              <span style={{ fontFamily: "monospace" }}>2k+</span>
              <span>
                <Typewriter
                  words={moto}
                  loop={Infinity}
                  typeSpeed={130}
                  deleteSpeed={130}
                  delaySpeed={2000}
                  cursorBlinking
                  cursor
                />
              </span>
            </h1>
          </div>
        </div>
      </div>
      <p id="reviews" style={{ width: "100% ", height: "1px" }}></p>
      <ReviewsContainer />

      <div className="footer">
        <div className="brand-info">
          <div className="brand-logo">
            <img src={logo} alt="logo" />
            <div className="logo-txt-group">
              <span className="txt">E</span>
              <span className="dash">-</span>
              <span className="txt">VIGILANTES</span>
            </div>
          </div>
          <div className="brand-social-con">
            <a
              href="https://www.linkedin.com/company/evigilantes/"
              target="_blank"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            <a
              href="https://youtube.com/@eVigilantes-lf2vh?si=mzJjB8Ltnl4nxQbo"
              target="_blank"
            >
              <img src={youtube} alt="youtube" />
            </a>
            <a href="https://www.instagram.com/evigilantes" target="_blank">
              <img src={instagram} alt="instagram" />
            </a>
          </div>
        </div>
        <div className="brand-address">
          <p>
            <span>Address: </span>1st Floor, Pinnacle Pride, Near Durvankur
            Dining Hall, Above Maharashtra Electronics Showroom, Opposite Cosmos
            Bank, Sadashiv Peth, Pune-411030
          </p>
          <p>
            <span>Email: </span>vansh@evigilantes.com
          </p>
          <p>
          </p>
        </div>
      </div>
    </>
  );
}

export default Main;
