import React, { useState } from "react";
import "./NavBarMobile.scss";
import menuCloseLogo from "../../assets/menu-close.png";
import menuOpenLogo from "../../assets/menu-open.png";
import logo from "../../assets/logo.png";

function NavBarMobile({ setRegisterCon }) {
  const [navBarOpened, setNavBarOpended] = useState(false);
  const setRegisterHolder = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setRegisterCon(true);
  };
  return (
    <div className="navbar-con">
      <div className="logo-holder">
        <img src={logo} />
        <span>E-VIGILANTES</span>
      </div>
      <div className="menu-btn-holder">
        <span className="btn-holder" onClick={setRegisterHolder}>
          Register
        </span>
      </div>
    </div>
  );
}

export default NavBarMobile;
