import React, { useState, useEffect } from "react";
import "./App.scss";
import NavBarWeb from "./Components/NavBarWeb/NavBarWeb";
import NavBarMobile from "./Components/NavBarMobile/NavBarMobile";
import Main from "./Components/Main/Main";
import Register from "./Components/Register/Register";

function App() {
  const [navbarWidht, setNavBarWidth] = useState(window.innerWidth >= 992);
  const [register, setRegister] = useState(false);

  const setRegisterCon = (val) => {
    setRegister(val);
  };

  // navbar visible
  useEffect(() => {
    const handleResize = () => {
      setNavBarWidth(window.innerWidth >= 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="main">
      {navbarWidht ? (
        <NavBarWeb setRegisterCon={setRegisterCon} />
      ) : (
        <NavBarMobile setRegisterCon={setRegisterCon} />
      )}
      <p id="home" style={{ width: "100%", height: "1px" }}></p>
      <Main />
      {register ? <Register setRegisterCon={setRegisterCon} /> : null}
    </div>
  );
}

export default App;
