import React from "react";
import "./Register.scss";

import register from "../../assets/register.jpg";
import close from "../../assets/menu-open.png";

function Register({ setRegisterCon }) {
  return (
    <div className="register-holder">
      <div className="register-con">
        <div className="img-holder">
          <img src={close} onClick={() => setRegisterCon(false)} />
        </div>
        <div className="navigation">
          <p>E-vigilants: Empowering your cybersecurity journey.</p>
        </div>
        <div className="img-holder-qr">
          <img src={register} />
          <p>OR</p>
          <a href="https://chat.whatsapp.com/INxKZzpBWSuKX87v9v0ydB">
            Click here
          </a>
        </div>
        <div className="content-holder">
          <h1>
            Join Us on <br /> <span>What's App</span>
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Register;
