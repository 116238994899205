import React, { useRef } from "react";
import "./Review.scss";

const ReviewsContainer = () => {
  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (window.innerWidth >= 992) {
      sliderRef.current.scrollBy({
        left: -700,
        behavior: "smooth",
      });
    } else {
      sliderRef.current.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (window.innerWidth >= 992) {
      sliderRef.current.scrollBy({
        left: 700,
        behavior: "smooth",
      });
    } else {
      sliderRef.current.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="reviews-holder">
      <div className="review-title-holder">
        <div className="review-title-content">
          <h1>
            Loved By Hackers. <br />
            Trusted By Students
          </h1>
        </div>
        <div className="review-title-btn">
          <button onClick={scrollLeft}>&#8592;</button>
          <button onClick={scrollRight}>&#8594;</button>
        </div>
      </div>
      <div className="review-con">
        <div className="review-slider" ref={sliderRef}>
          {/* review card start */}
          <div className="review-card">
            <div className="card-content">
              <p>
                E-Vigilantes is the go-to platform for anyone looking to enhance
                their online security knowledge. Being a part of the community,
                I appreciate how relevant and up-to-date their courses are for
                our digital landscape. The instructors are top-notch, and the
                practical exercises have truly honed my skills. I highly
                recommend E-Vigilantes to all seeking to safeguard their digital
                lives.
              </p>
            </div>
            <div className="card-info">
              <h3>Aniruddha Bhosale</h3>
            </div>
          </div>
          <div className="review-card">
            <div className="card-content">
              <p>
                E-Vigilantes has been a revelation for me. As a tech-savvy, I
                thought I knew a lot about online security, but this platform
                took my understanding to the next level. Their courses are not
                just theoretical; they provide real-world insights and
                solutions. I'm now better equipped to protect myself online and
                help others in my community stay safe too.
              </p>
            </div>
            <div className="card-info">
              <h3>Atharva Bodke</h3>
            </div>
          </div>
          <div className="review-card">
            <div className="card-content">
              <p>
                I can't thank E-Vigilantes enough for the incredible learning
                experience they've provided. As a student concerned about online
                security, their courses have been a game-changer. The content is
                not only informative but also presented in an engaging and
                easy-to-understand manner. Thanks to E-Vigilantes, I now feel
                more confident and capable in protecting myself and my online
                presence.
              </p>
            </div>
            <div className="card-info">
              <h3>Mandar Kulkarni</h3>
            </div>
          </div>
          {/* review card end */}
        </div>
        {/* <div className="slider-controls">
          <button onClick={scrollLeft}>Left</button>
          <button onClick={scrollRight}>Right</button>
        </div> */}
      </div>
    </div>
  );
};

export default ReviewsContainer;
