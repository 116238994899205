import React, { useState } from "react";
import "./NavBarWeb.scss";

import call from "../../assets/call.png";
import logo from "../../assets/logo.png";

function NavBarWeb({ setRegisterCon }) {
  const [active, setActive] = useState("home");
  const setRegisterHolder = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setRegisterCon(true);
  };

  return (
    <div className="navbar-con">
      <div className="logo-holder">
        <img src={logo} alt="logo" />
        <div className="logo-txt-group">
          <span className="txt">E</span>
          <span className="dash">-</span>
          <span className="txt">VIGILANTES</span>
        </div>
      </div>
      <div className="navigation-holder">
        <a
          href="#home"
          className={`nav-item ${active === "home" ? "active" : null}`}
          onClick={() => setActive("home")}
        >
          Home
        </a>
        <a
          href="#aboutus"
          className={`nav-item ${active === "about" ? "active" : null}`}
          onClick={() => setActive("about")}
        >
          About
        </a>
        <a
          href="#learning"
          className={`nav-item ${active === "learnings" ? "active" : null}`}
          onClick={() => setActive("learnings")}
        >
          Learning's
        </a>
        <a
          href="#reviews"
          className={`nav-item ${active === "reviews" ? "active" : null}`}
          onClick={() => setActive("reviews")}
        >
          Reviews
        </a>
      </div>
      <div className="info-holder">
        <span onClick={setRegisterHolder}>Register</span>
      </div>
    </div>
  );
}

export default NavBarWeb;
